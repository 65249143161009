
$headers:
/* name:mobile,768,992,1280,line-height,font */ 
(
  h1: ( 32px, 32px, 32px, 40px, 1.2, 'medium', ),
  h2: ( 24px, 24px, 24px, 32px, 1.2, 'light', ), 
  h3: ( 18px, 18px, 18px, 24px, 1.2, 'medium', ), 

  p1: ( 14px, 14px, 16px, 18px, 1.4, 'regular', ), 
  p2: ( 14px, 14px, 16px, 16px, 1.4, 'regular', ), 

  p3: ( 12px, 14px, 14px, 14px, 1.4, 'regular', ), 


);

$headersFluids: (

1: (60px, 160px, 0.8),
2: (40px, 100px, 0.9),  
3: (40px, 72px, 0.9), 
4: (30px, 64px, 0.9),  
5: (20px, 48px, 0.9),  
6: (18px, 24px, 1), 
7: (16px, 32px, 1.2), 
8: (14px, 24px, 1.2), 
9: (12px, 14px, 0.3),

) !default;

$mb: 'margin-bottom';
$margin-bottom: 
( 
mb-8: ( 8px, 8px, 8px, 8px, ),
mb-16: ( 16px, 16px, 16px, 16px, ),
mb-24: ( 24px, 24px, 24px, 24px, ),
mb-32: ( 32px, 32px, 32px, 32px, ), 
mb-40: ( 40px, 40px, 40px, 40px, ), 
mb-48: ( 48px, 48px, 48px, 48px, ),
mb-64: ( 64px, 64px, 64px, 64px, ), 
mb-82: ( 82px, 82px, 82px, 82px, ), 
mb-100: ( 100px, 100px, 100px, 100px, ),
mbr-50-100: ( 50px, 50px, 100px, 100px, ), 
mbr-24: ( 16px, 24px, 24px, 24px, ), 
mbr-32: ( 24px, 32px, 32px, 32px, ), 
mbr-64: ( 32px, 64px, 64px, 64px, ), 
mbr-128: ( 64px, 128px, 128px, 128px, ),
 ); //todo giftcard mbr-20-40: ( 20px, 40px, 40px, 40px, ), ) !default;

$font-family-a-l: 'font-light', Helvetica, 'Arial ', 'Lucida Grande', sans-serif;
$font-family-a-r: 'font-regular', Helvetica, 'Arial ', 'Lucida Grande', sans-serif; // las fuentes necesitan corrección de line-height ya que no todas son iguales
$font-family-a-m: 'font-regular',  Helvetica, 'Arial ', 'Lucida Grande', sans-serif;
$font-family-a-b: 'font-bold', Helvetica, 'Arial ', 'Lucida Grande', sans-serif;

$font-family-b-r: 'font-Regular', Helvetica, Arial, sans-serif;
$font-family-b-m: 'font-Regular', Helvetica,'Arial', sans-serif;
$font-family-b-b: 'font-Regular', Helvetica, 'arial black', sans-serif;

$font-family-c-r:'ms-serif',serif;
$font-family-c-m:  'ms-serif',serif;
$font-family-c-b:'ms-serif',serif;




$font-weight-light: inherit;
$font-weight-regular: inherit;
$font-weight-medium: inherit;
$font-weight-bold: inherit;

$font-family-form-placeholder: 'font-Regular';
$font-family-form: 'font-Regular';

$btn-radius: 8px;
$padding-global: 8px !default;  // esto hace que los botones peten porque dependen de esta variable


$container: 992px ;



// massimodutti color MD 2022
$c-white: #ffffff;
$c-black: #000000;

$c-dark-grey: #8e8e8e;
$c-middle-grey: #e3e3e3;
$c-light-grey: #f0f0f0;

$c-primary: #292929;
$c-error: #b40016;
$c-info: #1a32a4;

$c-success: #76ae4a;
$c-warning: #ffc700;
$c-feel: #fb9962;
// fin massimodutti color MD 2022

$height-md: 200px;
