

@font-face {
    font-family: 'font-light';
    src: url('../fonts/atlas/font-light.woff2') format('woff2'),
      url('../fonts/atlas/font-light.woff') format('woff');
  }
  @font-face {
    font-family: 'font-regular';
    src: url('../fonts/atlas/font-regular.woff2') format('woff2'),
      url('../fonts/atlas/font-regular.woff') format('woff');
  }
  
  
  @font-face {
    font-family: 'font-bold';
    src: url('../fonts/atlas/font-bold.woff2') format('woff2'),
      url('../fonts/atlas/font-bold.woff') format('woff');
  }
  