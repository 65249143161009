@import 'base/_fonts.scss';
@import 'abstract/_setup.scss';
@import '_swiper.scss';

$btn-radius: 50px;

@import '../../node_modules/holygrailcss/scss/style.scss';


img {
  max-width: initial;

}



.swiper {
  height: 400px;
}


$header-mobile: 60px;
$header-desktop:200px;


.p-body{
  padding-top: calc(#{$header-mobile} + 24px) ;

  @media (min-width: $break-md) {
    padding-top: calc(#{$header-desktop} + 24px) ;
  
  }

}


header {
  position: fixed;
  top :0;
  width: 100%;
  color: white;
  z-index: 10;
  height: $header-mobile;

  @media (min-width: $break-md) {
    padding-bottom: 0;
    height: $header-desktop;
  
  }
}

.header-img{
  background-image: url('../static/emotionheader.jpeg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
  }
}

.content-text{
  z-index: 2;
  position: relative;
}


